import { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import * as contentful from "contentful";
import { Mail, Github, Linkedin, ChevronDown } from "lucide-react";
import { ProjectLinks } from "./components/PRojectLinks";
import WaveClip from "./components/WaveClip";
import MobileWarning from "./components/MobileWarning";

export interface Project {
  title: string;
  description: string;
  screenshotUrls: string[];
  links: string[];
}

const App: React.FC = () => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [fullscreenImgSrc, setFullscreenImgSrc] = useState<string | null>(null);

  useEffect(() => {
    const client = contentful.createClient({
      space: "eu57fu0xi2p9",
      environment: "master",
      accessToken: "qoSAKhiqiUcebQIb2UYvQ0XjgTC54IO2sa0IxFUMm-U",
    });

    const fetchProjects = async () => {
      try {
        const entries = await client.getEntries<contentful.EntrySkeletonType>({
          content_type: "project",
        });

        const newProjects = entries.items.map((item) => {
          const {
            title = "",
            description = "",
            screenshots = [],
            links = [],
          } = item.fields as any;

          const screenshotUrls = screenshots.map((screenshot: any) => {
            if (screenshot.fields?.file?.url) {
              return `https:${screenshot.fields.file.url}`;
            }
            return "";
          });

          const linkUrls = links.map((link: string) => {
            return link;
          });

          return {
            title,
            description,
            screenshotUrls,
            links: linkUrls,
          };
        });
        setProjects(newProjects);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, []);

  useEffect(() => {
    const wrapper = document.createElement("div");
    wrapper.style.height = `${(projects.length + 2) * 100}vh`;
    document.body.appendChild(wrapper);

    const handleScroll = () => {
      const scrolled = window.scrollY;
      const viewportHeight = window.innerHeight;
      const totalHeight = wrapper.offsetHeight - viewportHeight;
      const scrollPercentage = (scrolled / totalHeight) * 100;
      setScrollProgress(Math.max(0, Math.min(100, scrollPercentage)));
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.body.removeChild(wrapper);
    };
  }, [projects.length]);

  const bgColors = [
    "bg-pink-100",
    "bg-teal-100",
    "bg-indigo-100",
    "bg-rose-100",
    "bg-sky-100",
    "bg-amber-100",
    "bg-lime-100",
    "bg-emerald-100",
    "bg-cyan-100",
    "bg-violet-100",
    "bg-fuchsia-100",
    "bg-orange-100",
    "bg-purple-100",
    "bg-green-100",
    "bg-blue-100",
    "bg-red-100",
    "bg-yellow-100",
  ];

  const projectsVisible = scrollProgress >= 15 && scrollProgress < 90;

  const landingTransform =
    scrollProgress <= 25
      ? `translateY(${-scrollProgress * 4}%)`
      : "translateY(-100%)";

  const horizontalProgress = Math.max(
    0,
    Math.min(100, (scrollProgress - 25) * 1.54)
  );

  const contactTransform = `translateY(-${
    scrollProgress >= 90 ? Math.max(0, 100 - (scrollProgress - 90) * 10) : 100
  }%)`;

  return (
    <div className="fixed top-0 left-0 w-screen h-screen overflow-hidden">
      <MobileWarning />

      {/* Landing Page */}
      <div
        className="absolute top-0 left-0 w-full h-full"
        style={{
          transform: landingTransform,
          zIndex: projects.length * 2 + 2,
        }}
      >
        {/* Dark Version */}
        <div className="absolute top-0 left-0 w-full h-full bg-black">
          <div className="flex flex-col items-center justify-center h-full text-center p-8">
            <h1 className="text-6xl  font-bold mb-6 mt-24 text-white ">
              Taymen Verschuren
            </h1>
            <p className="text-xl text-gray-200 font-bold max-w-2xl mb-12">
              Full Stack Developer
            </p>
            <div className="animate-bounce mt-20">
              <ChevronDown size={32} className="text-gray-200" />
            </div>
          </div>
        </div>

        <WaveClip>
          <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-br from-blue-100 to-purple-100">
            <div className="flex flex-col items-center justify-center h-full text-center p-8">
              <h1 className="text-6xl text-black mt-24 font-bold mb-6">
                Taymen Verschuren
              </h1>
              <p className="text-xl text-gray-800 font-bold max-w-2xl mb-12">
                Full Stack Developer
              </p>
              <div className="animate-bounce mt-20">
                <ChevronDown size={32} className="text-gray-800" />
              </div>
            </div>
          </div>
        </WaveClip>
      </div>

      {/* Projects Container */}
      <div className="absolute top-0 left-0 w-full h-full">
        {/* Project Backgrounds */}
        {projects.map((project, index) => {
          const position =
            ((horizontalProgress / 100) * (projects.length - 1) - index) * -100;
          return (
            <div
              key={`bg-${index}`}
              className={`absolute top-0 left-0 w-full h-full ${
                bgColors[index % bgColors.length]
              }`}
              style={{
                transform: `translateX(${position}%)`,
                zIndex: index * 2,
              }}
            />
          );
        })}

        {/* Project Content Sections */}
        {projects.map((project, index) => {
          const position =
            ((horizontalProgress / 100) * (projects.length - 1) - index) * -100;
          const clipLeft = Math.max(0, -position);
          const clipRight = Math.min(100, 100 - position);
          const clipPath = `inset(0 ${clipLeft}% 0 ${100 - clipRight}%)`;

          return (
            <div
              key={`text-${index}`}
              className="absolute top-0 left-0 w-full h-full overflow-hidden"
              style={{
                zIndex: index * 2 + 1,
                clipPath,
              }}
            >
              <div className="absolute top-0 left-0 w-full h-full p-12">
                <section className="flex h-full max-w-6xl mx-auto items-center gap-12">
                  <div className="w-1/2">
                    {project.screenshotUrls.length > 1 ? (
                      <Carousel
                        autoPlay
                        infiniteLoop
                        interval={
                          Math.floor(Math.random() * (3201 - 2500)) + 2500
                        }
                        swipeable={true}
                        showThumbs={false}
                        showStatus={false}
                        showIndicators={false}
                        onClickItem={(i: number) =>
                          setFullscreenImgSrc(project.screenshotUrls[i])
                        }
                      >
                        {project.screenshotUrls.map((url, index) => (
                          <div key={index} className="aspect-video">
                            <img
                              src={url}
                              alt={`Screenshot ${index + 1}`}
                              className="w-full h-full rounded-lg"
                            />
                          </div>
                        ))}
                      </Carousel>
                    ) : (
                      project.screenshotUrls[0] && (
                        <div className="aspect-video">
                          <img
                            onClick={() =>
                              setFullscreenImgSrc(project.screenshotUrls[0])
                            }
                            src={project.screenshotUrls[0]}
                            alt="Project screenshot"
                            className="w-full rounded-lg cursor-pointer"
                          />
                        </div>
                      )
                    )}
                  </div>
                  <div className="w-1/2 space-y-6">
                    <h2 className="text-4xl font-light">{project.title}</h2>
                    <p className="text-sm whitespace-pre-wrap">
                      {project.description}
                    </p>

                    <ProjectLinks links={project.links} />
                  </div>
                </section>
              </div>
            </div>
          );
        })}
      </div>

      {/* Contact Section */}
      <div
        className="absolute top-0 left-0 w-full h-full bg-gradient-to-br from-indigo-50 to-pink-50"
        style={{
          transform: contactTransform,
          zIndex: projects.length * 2 + 1,
        }}
      >
        <div className="flex flex-col items-center justify-center h-full text-center p-8">
          <h2 className="text-5xl font-light mb-8">Let's Connect</h2>
          <p className="text-xl text-gray-600 max-w-2xl mb-12">
            I'm always open to new opportunities and collaborations. Feel free
            to reach out through any of the channels below.
          </p>
          <div className="flex gap-8">
            {/* Email Button */}
            <a
              href="mailto:taymencontact@icloud.com"
              className="relative flex items-center gap-2 px-6 py-3 rounded-full border border-black overflow-hidden group"
              onMouseMove={(e) => {
                const rect = e.currentTarget.getBoundingClientRect();
                const x = e.clientX - rect.left;
                const y = e.clientY - rect.top;
                e.currentTarget.style.setProperty("--mouse-x", `${x}px`);
                e.currentTarget.style.setProperty("--mouse-y", `${y}px`);
              }}
            >
              <div
                className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                style={{
                  background: `radial-gradient(
            circle at var(--mouse-x, 50%) var(--mouse-y, 50%),
            rgba(251, 146, 60, 0.4) 0%,
            rgba(0, 0, 0, 0.95) 50%
          )`,
                }}
              />
              <Mail
                size={20}
                className="relative z-10 transition-colors group-hover:text-white"
              />
              <span className="relative z-10 transition-colors group-hover:text-white">
                Email
              </span>
            </a>

            {/* GitHub Button */}
            <a
              href="https://github.com/Taymen30"
              target="_blank"
              rel="noopener noreferrer"
              className="relative flex items-center gap-2 px-6 py-3 rounded-full border border-black overflow-hidden group"
              onMouseMove={(e) => {
                const rect = e.currentTarget.getBoundingClientRect();
                const x = e.clientX - rect.left;
                const y = e.clientY - rect.top;
                e.currentTarget.style.setProperty("--mouse-x", `${x}px`);
                e.currentTarget.style.setProperty("--mouse-y", `${y}px`);
              }}
            >
              <div
                className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                style={{
                  background: `radial-gradient(
            circle at var(--mouse-x, 50%) var(--mouse-y, 50%),
            rgba(251, 146, 60, 0.4) 0%,
            rgba(0, 0, 0, 0.95) 50%
          )`,
                }}
              />
              <Github
                size={20}
                className="relative z-10 transition-colors group-hover:text-white"
              />
              <span className="relative z-10 transition-colors group-hover:text-white">
                GitHub
              </span>
            </a>

            {/* LinkedIn Button */}
            <a
              href="https://www.linkedin.com/in/taymenverschuren/"
              target="_blank"
              rel="noopener noreferrer"
              className="relative flex items-center gap-2 px-6 py-3 rounded-full border border-black overflow-hidden group"
              onMouseMove={(e) => {
                const rect = e.currentTarget.getBoundingClientRect();
                const x = e.clientX - rect.left;
                const y = e.clientY - rect.top;
                e.currentTarget.style.setProperty("--mouse-x", `${x}px`);
                e.currentTarget.style.setProperty("--mouse-y", `${y}px`);
              }}
            >
              <div
                className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                style={{
                  background: `radial-gradient(
            circle at var(--mouse-x, 50%) var(--mouse-y, 50%),
            rgba(251, 146, 60, 0.4) 0%,
            rgba(0, 0, 0, 0.95) 50%
          )`,
                }}
              />
              <Linkedin
                size={20}
                className="relative z-10 transition-colors group-hover:text-white"
              />
              <span className="relative z-10 transition-colors group-hover:text-white">
                LinkedIn
              </span>
            </a>
          </div>
        </div>
      </div>

      {/* Navigation */}
      {
        <div
          className={`fixed bottom-8 right-8 flex gap-12 ${
            projectsVisible ? "opacity-100" : "opacity-0"
          } transition-all duration-700`}
          style={{
            zIndex: projects.length * 2 + 3,
            transform: projectsVisible ? "translateY(0)" : "translateY(50%)",
          }}
        >
          {projects.map((_, index) => {
            const progress = (horizontalProgress / 100) * (projects.length - 1);
            const isActive = progress >= index - 0.5 && progress < index + 0.5;
            return (
              <div key={index} className="flex items-center gap-4">
                <div className="w-24 h-px bg-black/30" />
                <span
                  className={`text-sm select-none ${
                    isActive ? "opacity-100" : "opacity-30"
                  }`}
                >
                  0{index + 1}
                </span>
              </div>
            );
          })}
          <div
            className="absolute h-px bg-black w-24 top-1/2"
            style={{
              left: `${
                ((horizontalProgress * 1.24) / 100) *
                (projects.length - 1) *
                (96 + 48)
              }px`,
            }}
          />
        </div>
      }

      {/* Fullscreen Image */}
      {fullscreenImgSrc && (
        <div
          className="fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center justify-center"
          onClick={() => setFullscreenImgSrc(null)}
        >
          <img
            src={fullscreenImgSrc}
            alt="Fullscreen view"
            className="max-w-[90vw] max-h-[90vh] object-contain"
          />
        </div>
      )}
    </div>
  );
};

export default App;
