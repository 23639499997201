import React, { useEffect, useState } from "react";

const MobileWarning = () => {
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);

  useEffect(() => {
    const checkDevice = () => {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    };
    setIsMobileOrTablet(checkDevice());
  }, []);

  if (!isMobileOrTablet) return null;

  return (
    <div className="fixed inset-0 bg-black flex items-center justify-center p-8 z-50">
      <div className="bg-white rounded-lg shadow-lg max-w-md w-full p-6 space-y-4">
        <div className="space-y-2">
          <h2 className="text-xl font-semibold flex items-center gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <rect x="7" y="4" width="10" height="16" rx="1" ry="1" />
              <path d="M11 5h2" />
              <path d="M12 17v.01" />
            </svg>
            Mobile Device Detected
          </h2>
          <p className="text-gray-600">
            Oops! Looks like you're visiting on a mobile device. Unfortunately,
            this version of my portfolio isn't optimised for touch inputs.
          </p>
          <p className="text-gray-600">
            Please visit my mobile-optimized version instead for the best
            experience. Alternatively revisit on a computer.
          </p>
        </div>
        <button
          onClick={() =>
            (window.location.href =
              "https://portfolio-taymen30s-projects.vercel.app")
          }
          className="w-full px-6 py-3 rounded-full font-medium text-white bg-gradient-to-r from-purple-500 to-indigo-600"
        >
          Take me to the mobile version
        </button>
      </div>
    </div>
  );
};

export default MobileWarning;
