import React, { useState, useEffect, useRef } from "react";

interface WavePoint {
  x: number;
  y: number;
  vy: number;
  targetY: number;
}

interface MousePosition {
  x: number;
  y: number;
}

interface WaveClipProps {
  children: React.ReactNode;
}

const WaveClip: React.FC<WaveClipProps> = ({ children }) => {
  const [points, setPoints] = useState<WavePoint[]>([]);
  const [mousePos, setMousePos] = useState<MousePosition>({ x: 0, y: 0 });
  const requestRef = useRef<number | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  // Force initial render of wave points
  useEffect(() => {
    const initializePoints = () => {
      const numPoints = 20;
      const initialPoints = Array.from({ length: numPoints }, (_, i) => ({
        x: (window.innerWidth * i) / (numPoints - 1),
        y: window.innerHeight / 2,
        vy: 0,
        targetY: window.innerHeight / 2,
      }));
      setPoints(initialPoints);
    };

    initializePoints();
    // Force a second initialization after a brief delay
    const timeout = setTimeout(initializePoints, 100);

    const handleResize = () => {
      initializePoints();
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      clearTimeout(timeout);
    };
  }, []);

  const animate = () => {
    setPoints((currentPoints) => {
      return currentPoints.map((point) => {
        const dx = mousePos.x - point.x;
        const dy = mousePos.y - point.y;
        const distance = Math.sqrt(dx * dx + dy * dy);

        const radius = 300;
        let newTargetY = window.innerHeight / 2;

        if (distance < radius) {
          const force = (1 - distance / radius) * 200;
          newTargetY += force * (mousePos.y > point.y ? -1 : 1);
        }

        const spring = 0.08;
        const damping = 0.8;
        const acceleration = (newTargetY - point.y) * spring;

        return {
          ...point,
          vy: (point.vy + acceleration) * damping,
          y: point.y + point.vy,
          targetY: newTargetY,
        };
      });
    });

    requestRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, [mousePos]);

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    const rect = containerRef.current?.getBoundingClientRect();
    if (rect) {
      setMousePos({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      });
    }
  };

  const createWavePath = () => {
    if (points.length < 2) return "";

    const path = points.reduce((acc, point, i) => {
      if (i === 0) return `M ${point.x},${point.y}`;
      const prevPoint = points[i - 1];
      const controlX = (prevPoint.x + point.x) / 2;
      return `${acc} C ${controlX},${prevPoint.y} ${controlX},${point.y} ${point.x},${point.y}`;
    }, "");

    return `${path} L ${window.innerWidth},${window.innerHeight} L 0,${window.innerHeight} Z`;
  };

  return (
    <div
      ref={containerRef}
      className="absolute inset-0"
      onMouseMove={handleMouseMove}
      style={{ cursor: "none" }}
    >
      <div
        className="fixed w-8 h-8 rounded-full mix-blend-difference bg-white pointer-events-none"
        style={{
          transform: `translate(${mousePos.x - 16}px, ${mousePos.y - 16}px)`,
          transition: "transform 0.1s ease-out",
          zIndex: 9999,
        }}
      />

      <div
        className="absolute inset-0"
        style={{
          clipPath: `path('${createWavePath()}')`,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default WaveClip;
