import React from "react";
import { Globe, Store, Github } from "lucide-react";
import { ProjectLinksProps } from "../types/types";
import { DraggableLink } from "./DraggableLink";

export const ProjectLinks: React.FC<ProjectLinksProps> = ({ links }) => {
  const getLinkIcon = (link: string) => {
    if (link.includes("github.com")) return <Github className="w-4 h-4" />;
    if (link.includes("apple")) return <Store className="w-4 h-4" />;
    return <Globe className="w-4 h-4" />;
  };

  const getLinkText = (link: string): string => {
    if (link.includes("github.com")) return "GitHub";
    if (link.includes("apple")) return "App";
    return "Web";
  };

  return (
    <div className="relative min-h-[64px]">
      {links.map((link, index) => (
        <DraggableLink
          key={index}
          href={link}
          initialPosition={{ x: index * 120, y: 0 }}
        >
          {getLinkIcon(link)}
          <span>{getLinkText(link)}</span>
        </DraggableLink>
      ))}
    </div>
  );
};
