import React, { useState, useEffect, useRef } from "react";
import { Position, DraggableLinkProps } from "../types/types";

export const DraggableLink: React.FC<DraggableLinkProps> = ({
  href,
  children,
  initialPosition = { x: 0, y: 0 },
}) => {
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [position, setPosition] = useState<Position>(initialPosition);
  const [dragStart, setDragStart] = useState<Position>({ x: 0, y: 0 });
  const hasMoved = useRef<boolean>(false);
  const startPosition = useRef<Position>({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (!isDragging) return;

      const newX = e.clientX - dragStart.x;
      const newY = e.clientY - dragStart.y;

      if (
        Math.abs(newX - startPosition.current.x) > 5 ||
        Math.abs(newY - startPosition.current.y) > 5
      ) {
        hasMoved.current = true;
      }

      setPosition({
        x: newX,
        y: newY,
      });
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      setTimeout(() => {
        hasMoved.current = false;
      }, 0);
    };

    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, dragStart]);

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsDragging(true);
    startPosition.current = position;
    hasMoved.current = false;
    setDragStart({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  const handleClick = (e: React.MouseEvent) => {
    if (hasMoved.current) {
      e.preventDefault();
    }
  };

  return (
    <div
      style={{
        position: "absolute",
        left: position.x,
        top: position.y,
        cursor: isDragging ? "grabbing" : "grab",
        zIndex: isDragging ? 50 : 1,
        transform: `translate(0, 0)`,
        userSelect: "none",
        touchAction: "none",
      }}
      onMouseDown={handleMouseDown}
    >
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        style={{ cursor: isDragging ? "grabbing" : "grab" }}
        className="flex items-center gap-2 px-4 py-2 rounded-full border border-black hover:bg-black hover:text-white transition-colors"
        onClick={handleClick}
      >
        {children}
      </a>
    </div>
  );
};
